<template>
  <iframe :src="iframeSrc"></iframe>
</template>

<script>
export default {
  data() {
    return {
      iframeSrc: 'https://share.tcbi.qq.com/page/share?pageId=10539566&projectId=11025336&token=138a97b1-0619-48e8-b55c-d7c666d45992&scope=page&canvasType=GRID' // 替换为你想要嵌入的网站URL
    };
  }
};
</script>

<style>
/* 可以添加一些基本样式 */
iframe {
  width: 100%;
  height: 100%;
  border: none;
}
</style>